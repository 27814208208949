import {
  TextField,
  SelectField,
  SubmitButton,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import OnboardingStepper from "src/components/Onboarding/OnboardingStepper";
import SplitPage from "src/components/Onboarding/SplitPage";
import {
  usePendingAssistedReferralQuery,
  usePatientQuery,
  useUpdateHealthInfoMutation,
  useUpdateOnboardingStatusMutation,
} from "src/queries";

const CONDITIONS = [
  { label: "Anxiety", value: "Anxiety", isPsychiatric: true, isPain: false },
  {
    label: "Agoraphobia",
    value: "Agoraphobia",
    isPsychiatric: true,
    isPain: false,
  },
  {
    label: "Arthritis",
    value: "Arthritis",
    isPsychiatric: false,
    isPain: true,
  },
  {
    label: "Attention Deficit Hyperactivity Disorder (ADHD)",
    value: "Attention Deficit Hyperactivity Disorder (ADHD)",
    isPsychiatric: true,
    isPain: false,
  },
  {
    label: "Autistic Spectrum Disorder",
    value: "Autistic Spectrum Disorder",
    isPsychiatric: true,
    isPain: false,
  },
  {
    label: "Breast Pain",
    value: "Breast Pain",
    isPsychiatric: false,
    isPain: true,
  },
  {
    label: "Cancer-Related Appetite Loss",
    value: "Cancer-Related Appetite Loss",
    isPsychiatric: false,
    isPain: false,
  },
  {
    label: "Cancer Related Pain & Other Secondary Symptoms",
    value: "Cancer Related Pain & Other Secondary Symptoms",
    isPsychiatric: false,
    isPain: true,
  },
  {
    label: "Chemotherapy Induced Nausea and Vomiting",
    value: "Chemotherapy Induced Nausea and Vomiting",
    isPsychiatric: false,
    isPain: false,
  },
  {
    label: "Chronic Fatigue Syndrome / ME",
    value: "Chronic Fatigue Syndrome / ME",
    isPsychiatric: false,
    isPain: false,
  },
  {
    label: "Chronic Pain",
    value: "Chronic Pain",
    isPsychiatric: false,
    isPain: true,
  },
  {
    label: "Complex Regional Pain Syndrome",
    value: "Complex Regional Pain Syndrome",
    isPsychiatric: false,
    isPain: true,
  },
  {
    label: "Cluster Headache",
    value: "Cluster Headache",
    isPsychiatric: false,
    isPain: true,
  },
  {
    label: "Crohn's Disease",
    value: "Crohn's Disease",
    isPsychiatric: false,
    isPain: false,
  },
  {
    label: "Depression",
    value: "Depression",
    isPsychiatric: true,
    isPain: false,
  },
  {
    label: "Ehlers Danlos Syndrome",
    value: "Ehlers Danlos Syndrome",
    isPsychiatric: false,
    isPain: true,
  },
  {
    label: "Endometriosis",
    value: "Endometriosis",
    isPsychiatric: false,
    isPain: true,
  },
  {
    label: "Epilepsy - Adult/Child",
    value: "Epilepsy - Adult/Child",
    isPsychiatric: false,
    isPain: false,
  },
  {
    label: "Fibromyalgia",
    value: "Fibromyalgia",
    isPsychiatric: false,
    isPain: true,
  },
  { label: "Insomnia", value: "Insomnia", isPsychiatric: false, isPain: false },
  {
    label: "Low Back Pain and Sciatica",
    value: "Low Back Pain and Sciatica",
    isPsychiatric: false,
    isPain: true,
  },
  { label: "Migraine", value: "Migraine", isPsychiatric: false, isPain: true },
  {
    label: "Multiple Sclerosis (MS)",
    value: "Multiple Sclerosis (MS)",
    isPsychiatric: false,
    isPain: false,
  },
  {
    label: "Musculoskeletal Pain",
    value: "Musculoskeletal Pain",
    isPsychiatric: false,
    isPain: true,
  },
  {
    label: "Neuropathic Pain",
    value: "Neuropathic Pain",
    isPsychiatric: false,
    isPain: true,
  },
  {
    label: "Obsessive-Compulsive Disorder (OCD)",
    value: "Obsessive-Compulsive Disorder (OCD)",
    isPsychiatric: true,
    isPain: false,
  },
  {
    label: "Other Gastrointestinal Condition",
    value: "Other Gastrointestinal Condition",
    isPsychiatric: false,
    isPain: false,
  },
  {
    label: "Palliative Care",
    value: "Palliative Care",
    isPsychiatric: false,
    isPain: false,
  },
  {
    label: "Parkinson's Disease",
    value: "Parkinson's Disease",
    isPsychiatric: false,
    isPain: false,
  },
  {
    label: "Social Phobia",
    value: "Social Phobia",
    isPsychiatric: true,
    isPain: false,
  },
  {
    label: "Tourette's Syndrome",
    value: "Tourette's Syndrome",
    isPsychiatric: true,
    isPain: false,
  },
  {
    label: "Trigeminal Neuralgia",
    value: "Trigeminal Neuralgia",
    isPsychiatric: false,
    isPain: false,
  },
  {
    label: "Ulcerative Colitis",
    value: "Ulcerative Colitis",
    isPsychiatric: false,
    isPain: false,
  },
];

interface IForm {
  allergies?: string;
  clinicName?: string;
  condition: string;
  hasAllergies: string;
  isTransfer: string;
  medicineHistory: string;
  painHistory?: string;
  psychiatricHistory?: string;
}

const validationSchema = z
  .object({
    allergies: z.string(),
    clinicName: z.string(),
    condition: z.string(),
    hasAllergies: z.string(),
    isTransfer: z.string(),
    medicineHistory: z.string(),
    painHistory: z.string(),
    psychiatricHistory: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.hasAllergies === "Yes" && !data.allergies) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Please list allergies if you have selected 'Yes' for allergies",
        path: ["allergies"],
      });
    }
    if (data.isTransfer === "Yes" && data.clinicName === "") {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Please enter the name of the Clinic you are transferring from",
        path: ["clinicName"],
      });
    }
  });

const HealthInfo = () => {
  const { data: patient } = usePatientQuery();
  const { data: assistedReferral } = usePendingAssistedReferralQuery();
  const { mutateAsync: updateOnboardingStatus } =
    useUpdateOnboardingStatusMutation();
  const { mutateAsync: update } = useUpdateHealthInfoMutation();
  const [_, setLocation] = useLocation();

  const defaultValues = {
    allergies: assistedReferral?.allergies ?? "",
    clinicName: assistedReferral?.clinicName ?? "",
    condition: assistedReferral?.condition ?? "",
    hasAllergies:
      (assistedReferral?.allergies ?? null) === null
        ? ""
        : assistedReferral?.allergies !== ""
          ? "Yes"
          : "No",
    isTransfer:
      (assistedReferral?.isTransfer ?? null) === null
        ? ""
        : assistedReferral?.isTransfer
          ? "Yes"
          : "No",
    medicineHistory: assistedReferral?.medicineHistory ?? "",
    painHistory: assistedReferral?.painHistory ?? "",
    psychiatricHistory: assistedReferral?.psychiatricHistory ?? "",
  };

  const methods = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues,
  });

  const values = methods.watch();
  const { isPain, isPsychiatric } =
    CONDITIONS.find((item) => item.value === values.condition) ?? {};
  useEffect(
    () => methods.reset(defaultValues),
    [assistedReferral, methods.reset],
  );

  const onSubmit = async (data: IForm) => {
    await update({
      allergies: data.allergies ?? "",
      clinicName: data.clinicName ?? "",
      condition: data.condition,
      isTransfer: data.isTransfer === "Yes",
      medicineHistory: data.medicineHistory,
      painHistory: data.painHistory ?? "",
      psychiatricHistory: data.psychiatricHistory ?? "",
    });

    if (patient!.onboardingStatus === "healthInfo") {
      await updateOnboardingStatus("gpInfo");
    }
    setLocation("/onboarding/gp/");
  };

  return (
    <SplitPage
      left={<OnboardingStepper />}
      right={
        <Stack spacing={2}>
          <Typography variant="h2">About your health</Typography>
          <Card>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <CardContent>
                  <Stack spacing={2}>
                    <SelectField
                      fullWidth
                      helperText="Please enter the reason you'd like to be considered for treatment"
                      label="Reason for treatment"
                      name="condition"
                      options={CONDITIONS}
                      required
                    />
                    <TextField
                      fullWidth
                      helperText="What medicines or treatments have you already tried to treat your condition? Please tell us about any treatments specialists have recommended."
                      label="Your medicine history"
                      minRows={2}
                      multiline
                      name="medicineHistory"
                      required
                    />
                    {isPain ? (
                      <TextField
                        fullWidth
                        helperText="Please tell us about your pain/investigations related to your condition"
                        label="Your pain history"
                        minRows={2}
                        multiline
                        name="painHistory"
                        required
                      />
                    ) : null}
                    {isPsychiatric ? (
                      <TextField
                        fullWidth
                        helperText="Please tell us about the psychological/talking therapy you may have had. Please note, our specialists will require written confirmation about the therapy and medicines you may have been prescribed to treat your condition."
                        label="Your therapy history"
                        minRows={2}
                        multiline
                        name="psychiatricHistory"
                        required
                      />
                    ) : null}
                    <SelectField
                      fullWidth
                      label="Do you have any allergies?"
                      name="hasAllergies"
                      options={[{ value: "Yes" }, { value: "No" }]}
                      required
                    />
                    {values.hasAllergies === "Yes" ? (
                      <TextField
                        fullWidth
                        label="Please tell us about your allergies"
                        minRows={2}
                        multiline
                        name="allergies"
                        required
                      />
                    ) : null}
                    <SelectField
                      fullWidth
                      helperText="Please only enter if the clinic is a medical cannabis clinic"
                      label="Are you transferring from another clinic?"
                      name="isTransfer"
                      options={[{ value: "Yes" }, { value: "No" }]}
                      required
                    />
                    {values.isTransfer === "Yes" ? (
                      <TextField
                        fullWidth
                        label="Name of clinic"
                        multiline
                        name="clinicName"
                        required
                      />
                    ) : null}
                  </Stack>
                </CardContent>
                <CardActions>
                  <SubmitButton
                    disabled={patient === undefined}
                    fullWidth
                    label="Save & Continue"
                  />
                </CardActions>
              </form>
            </FormProvider>
          </Card>
        </Stack>
      }
      title="About your health"
    />
  );
};

export default HealthInfo;

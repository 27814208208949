import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import PricingInfoColumn from "src/components/LandingPage/PricingInfoColumn";

const Pricing = () => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  return (
    <Grid
      container
      direction="column"
      sx={{
        padding: { xs: "20px", md: "50px" },
        margin: { xs: "5px", sm: "10px" },
      }}
    >
      <Typography
        component="h2"
        variant="landingH3"
        sx={{
          color: darkMode
            ? theme.palette.brand.contrastText
            : theme.palette.brand.main,
          paddingBottom: "20px",
        }}
      >
        Pricing{" "}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ width: { xs: "100%", md: "60%" }, paddingBottom: "30px" }}
      >
        We aim to make accessing private care as affordable as possible. You can
        choose to pay per appointment or spread the cost of regular appointments
        with a monthly subscription. Regardless of how you choose to pay, all
        patients can expect personalised care, discounts in year two and no
        repeat prescription fees.
      </Typography>
      <Grid container spacing="20px">
        <Grid size={{ xs: 12, lg: 7 }}>
          <Card
            variant="landing"
            sx={{
              border: `2px solid ${theme.palette.primary.main}`,
              backgroundColor: alpha(theme.palette.primary.main, 0.06),
            }}
          >
            <CardContent
              sx={{
                padding: {
                  xs: "30px 20px !important",
                  md: "50px 40px !important",
                },
              }}
            >
              <Grid container direction="column" spacing="20px">
                <Chip
                  variant="filled"
                  color="primary"
                  label="NEW"
                  sx={{ alignSelf: "flex-start" }}
                />
                <Typography component="h3" variant="landingH4">
                  Subscription
                </Typography>
                <Divider />
                <Grid container>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <PricingInfoColumn
                      header="Year One"
                      price={15}
                      priceFrequency="per month"
                      showRegistryBulletpoint={true}
                      bulletPoints={[
                        "New and existing patients pay £15.00 per month across first year",
                        "Covers the cost of initial appointment and four routine check-ups",
                        "3-month notice period with 4-month minimum terms for new patients",
                      ]}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <PricingInfoColumn
                      header="Year Two"
                      price={10}
                      priceFrequency="per month"
                      showRegistryBulletpoint={true}
                      bulletPoints={[
                        "New and existing patients pay £10.00 per month in year two and beyond",
                        "Covers four appointments per year",
                        "3-month notice period",
                      ]}
                    />
                  </Grid>
                </Grid>
                <Button
                  href={`${window.location.origin}/sign-up/`}
                  target="_blank"
                  variant="contained"
                  sx={{
                    alignSelf: "flex-end",
                    padding: { md: "12px 28px" },
                    color: darkMode
                      ? theme.palette.neutral[1000]
                      : theme.palette.brand.contrastText,
                    fontSize: "1.125rem",
                    fontWeight: 500,
                    lineHeight: 1.25,
                  }}
                >
                  Register with Curaleaf Clinic
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, lg: 5 }}>
          <Card
            variant="landing"
            sx={{
              height: "100%",
              background: "transparent",
              border: `1px solid ${alpha(darkMode ? theme.palette.brand.contrastText : theme.palette.brand.main, 0.4)}`,
            }}
          >
            <CardContent
              sx={{
                height: "100%",
                padding: {
                  xs: "30px 20px !important",
                  lg: "50px 40px !important",
                },
              }}
            >
              <Grid container direction="column" spacing="20px" height="100%">
                <Typography
                  component="h3"
                  variant="landingH4"
                  paddingTop={{ xs: "0px", lg: "55px" }}
                >
                  Pay per Appointment
                </Typography>
                <Divider />
                <PricingInfoColumn
                  header="Per Appointment"
                  price={50}
                  priceFrequency=""
                  bulletPoints={[
                    "£50.00 per appointment, paid at time of booking",
                    "New patients typically have four to five appointments in the first year",
                    "Join the Curaleaf Access Scheme and your first follow-up is free",
                    "From year two, some patients may only need two appointments per year",
                  ]}
                />
                <Button
                  href={`${window.location.origin}/sign-up/`}
                  target="_blank"
                  variant="outlined"
                  sx={{
                    alignSelf: "flex-end",
                    fontSize: "1.125rem",
                    fontWeight: 500,
                    lineHeight: 1.25,
                    marginTop: "auto",
                    padding: { md: "12px 28px" },
                  }}
                >
                  Register with Curaleaf Clinic
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Pricing;

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { forwardRef, useEffect, useRef } from "react";
import SignaturePad from "react-signature-pad-wrapper";

export class NoSignatureError extends Error {
  constructor(message: string) {
    super(message);
  }
}

interface IProps {
  defaultSignature?: string;
}

const SignatureBox = forwardRef<any, IProps>(({ defaultSignature }, ref) => {
  const canvasRef = useRef<any>({});

  useEffect(() => {
    (ref as any).current = async () => {
      if (canvasRef.current === undefined || canvasRef.current.isEmpty()) {
        throw new NoSignatureError("No signature");
      }

      return await new Promise((resolve) =>
        canvasRef.current.canvas.current.toBlob(resolve, "image/png"),
      );
    };
  }, [canvasRef, ref]);

  const onAutoGenerate = () => {
    if (canvasRef.current !== undefined && defaultSignature !== undefined) {
      canvasRef.current.clear();
      const ctx = canvasRef.current.canvas.current.getContext("2d");
      ctx.font = "50px cursive";
      ctx.fillText(defaultSignature, 10, 80, 300);
      canvasRef.current.signaturePad._isEmpty = false;
    }
  };

  const onClear = () => {
    if (canvasRef.current !== undefined) {
      canvasRef.current.clear();
    }
  };

  return (
    <>
      <Box sx={{ border: 1, width: "340px" }}>
        <SignaturePad
          options={{ penColor: "black", backgroundColor: "white" }}
          ref={canvasRef}
        />
      </Box>
      <ButtonGroup size="medium" aria-label="Signature controls">
        <Button color="secondary" onClick={onClear} variant="outlined">
          Clear signature
        </Button>
        <Button
          color="secondary"
          disabled={defaultSignature === undefined}
          onClick={onAutoGenerate}
          variant="outlined"
        >
          Generate signature
        </Button>
      </ButtonGroup>
    </>
  );
});
SignatureBox.displayName = "SignatureBox";

export default SignatureBox;

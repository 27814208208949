import { ToastContext } from "@curaleaf-international/components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  browserSessionPersistence,
  inMemoryPersistence,
  setPersistence,
  signInWithCustomToken,
  Persistence,
} from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { Link as WLink, useLocation } from "wouter";

import { AuthContext } from "src/AuthContext";
import AppleSignInButton from "src/components/Onboarding/AppleSignInButton";
import GoogleSignInButton from "src/components/Onboarding/GoogleSignInButton";
import MicrosoftSignInButton from "src/components/Onboarding/MicrosoftSignInButton";
import NHSSignInButton from "src/components/Onboarding/NHSSignInButton";
import Page from "src/components/Onboarding/Page";
import { auth } from "src/firebase";
import { newAuthUser } from "src/models";
import EmailPasswordForm from "src/pages/SignIn/EmailPasswordForm";
import LinkForm from "src/pages/SignIn/LinkForm";

const SignIn = () => {
  const [_, setLocation] = useLocation();
  const [mode, setMode] = useState("password");
  const { addToast } = useContext(ToastContext);
  const { authenticate } = useContext(AuthContext);

  const handleSignInWithCustomToken = async (
    token: string,
    persistence: Persistence,
  ) => {
    await setPersistence(auth, persistence);
    const { user } = await signInWithCustomToken(auth, token);
    authenticate(newAuthUser(user));
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const redirect = urlParams.get("redirect");
    if (urlParams.get("code") !== null) {
      setLocation("/nhs-callback/?" + urlParams.toString());
    } else if (
      urlParams.get("error") === "access_denied" &&
      urlParams.get("error_description") === "ConsentNotGiven"
    ) {
      addToast("Consent required to continue with NHS login", "error");
    } else if (token !== null) {
      void handleSignInWithCustomToken(token, inMemoryPersistence);
    } else if (redirect !== null) {
      void handleSignInWithCustomToken(redirect, browserSessionPersistence);
    }
  }, [handleSignInWithCustomToken]);

  return (
    <Page title="Sign In">
      <Box
        sx={{
          marginLeft: { sm: 10, xs: 0 },
          marginRight: { sm: 10, xs: 0 },
        }}
      >
        <Typography marginBottom={2} variant="h2">
          Sign in
        </Typography>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography gutterBottom variant="body1">
                Sign into your account to check the progress of your application
                or manage your care online.
              </Typography>
              <NHSSignInButton />
              <Divider
                sx={{ paddingTop: 2, paddingBottom: 2, alignItems: "center" }}
              >
                OR
              </Divider>
              {mode === "password" ? <EmailPasswordForm /> : <LinkForm />}
              <Button
                fullWidth
                onClick={() =>
                  setMode((mode) => (mode === "password" ? "link" : "password"))
                }
                variant="outlined"
                color="secondary"
              >
                {mode === "password"
                  ? "Sign In with Secure Link"
                  : "Sign In with Password"}
              </Button>
              <Divider />
              <Button
                color="primary"
                component={WLink}
                fullWidth
                to="/sign-up/"
                variant="outlined"
              >
                Don't have an account? Sign Up
              </Button>
              <Divider
                sx={{ paddingTop: 2, paddingBottom: 2, alignItems: "center" }}
              >
                OR
              </Divider>
              <GoogleSignInButton />
              <MicrosoftSignInButton />
              <AppleSignInButton />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

export default SignIn;

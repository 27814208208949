import LoadingButton from "@mui/lab/LoadingButton";
import { OAuthProvider, signInWithRedirect } from "firebase/auth";

import AppleLogo from "src/components/Onboarding/AppleLogo";
import { auth } from "src/firebase";

const AppleSignInButton = () => {
  const onClick = async () => {
    const provider = new OAuthProvider("apple.com");
    await signInWithRedirect(auth, provider);
  };

  return (
    <LoadingButton
      fullWidth
      onClick={onClick}
      startIcon={<AppleLogo />}
      variant="outlined"
      color="secondary"
    >
      Continue with Apple
    </LoadingButton>
  );
};

export default AppleSignInButton;

import {
  CheckboxField,
  EmailField,
  PasswordField,
  SubmitButton,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import * as Sentry from "@sentry/react";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link as WLink } from "wouter";
import { z } from "zod";

import { AuthContext } from "src/AuthContext";
import { auth } from "src/firebase";
import { newAuthUser } from "src/models";

interface IForm {
  email: string;
  password: string;
  remember: boolean;
}

const validationSchema = z.object({
  email: z.string().email("Invalid email"),
  password: z.string(),
  remember: z.boolean(),
});

const EmailPasswordForm = () => {
  const { addToast } = useContext(ToastContext);
  const { authenticate } = useContext(AuthContext);
  const methods = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: "",
      password: "",
      remember: false,
    },
  });

  const onSubmit = async (data: IForm) => {
    try {
      await setPersistence(
        auth,
        data.remember ? browserLocalPersistence : browserSessionPersistence,
      );
      const { user } = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password,
      );
      authenticate(newAuthUser(user));
    } catch (error: any) {
      if (error.message.includes("NHS login")) {
        addToast("Please use NHS login to sign in", "error");
      } else if (
        error.code === "auth/wrong-password" ||
        error.code === "auth/user-not-found" ||
        error.code === "auth/invalid-login-credentials" ||
        error.code === "auth/invalid-credential"
      ) {
        addToast(
          "Email and/or password are incorrect. Please try again.",
          "error",
        );
      } else if (error.code === "auth/too-many-requests") {
        addToast(
          "Too many failed attempts. Please try again later or reset password.",
          "error",
        );
      } else {
        Sentry.captureException(error);
        addToast(
          "Something went wrong. Please refresh the page or try again.",
          "error",
        );
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        sx={{ width: "100%" }}
      >
        <EmailField fullWidth label="Email" name="email" required />
        <PasswordField
          autoComplete="password"
          fullWidth
          label="Password"
          name="password"
          required
        />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <CheckboxField label="Remember me" name="remember" />
          <Link
            component={WLink}
            sx={{ textAlign: "right" }}
            to="/forgotten-password/"
          >
            Forgotten password?
          </Link>
        </Stack>
        <SubmitButton fullWidth label="Sign In" />
      </Box>
    </FormProvider>
  );
};

export default EmailPasswordForm;

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ChangeEvent, useState } from "react";

interface IProps {
  onChange: (value: string) => void;
}

const SCRChoiceCard = ({ onChange }: IProps) => {
  const [choice, setChoice] = useState("sapphire");

  return (
    <Card>
      <CardContent>
        <FormControl>
          <RadioGroup
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setChoice((event.target as HTMLInputElement).value)
            }
            sx={{ rowGap: 2 }}
            value={choice}
          >
            <FormControlLabel
              value="sapphire"
              control={<Radio />}
              label="I would like Curaleaf Clinic to access my Summary Care Record and clinical letters related to my condition from my GP manually OR from the NHS National Care Record Service."
            />
            <FormControlLabel
              value="direct"
              control={<Radio />}
              label="I will provide my Summary Care Record and clinical letters related to my condition from my GP by uploading them on the next page."
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
      <CardActions>
        <Button fullWidth onClick={() => onChange(choice)} variant="contained">
          Save & Continue
        </Button>
      </CardActions>
    </Card>
  );
};

export default SCRChoiceCard;

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/react";
import { useEffect, useRef, useState } from "react";
import { Link as WLink, useLocation } from "wouter";

import Loader from "src/components/Onboarding/Loader";
import Page from "src/components/Onboarding/Page";
import { useNHSSignInMutation } from "src/queries";

const NHSCallback = () => {
  const [_, setLocation] = useLocation();
  const [error, setError] = useState<string | null>(null);
  const { mutateAsync: signIn } = useNHSSignInMutation();
  const effectRan = useRef(false);

  useEffect(() => {
    const login = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      if (effectRan.current) return;
      if (code !== null) {
        effectRan.current = true;
        const redirectUri = new URL("/sign-in/", window.location.origin);
        try {
          await signIn({
            code,
            redirectUri: redirectUri.toString(),
          });
          setLocation("/sign-in/");
        } catch (error: any) {
          if (error.message === "UNDER_18") {
            setError(
              "You must be at least 18 years old to apply for treatment.",
            );
            return;
          } else if (error.message === "INVALID_VOT") {
            setError(
              "You must have consented to share your information with Curaleaf Clinic to sign in with NHS login.",
            );
            return;
          } else if (error.message === "INVALID_TOKEN") {
            Sentry.captureException(error);
          } else {
            setError(
              "Unable to sign in with NHS login. There was an issue on our end. Please try again later or use another sign in method.",
            );
          }
        }
      } else {
        setError(
          "You did not give consent for Curaleaf Clinic to access your information. Try again or use another sign in method.",
        );
      }
    };
    void login();
  }, [signIn]);

  return (
    <Page title="NHS">
      {error === null ? (
        <Stack spacing={1}>
          <Card>
            <CardContent>
              <Typography component="h2" variant="h2">
                Retrieving your information from NHS login. This may take a few
                seconds.
              </Typography>
            </CardContent>
          </Card>
          <Loader />
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Typography component="h2" variant="h5">
            NHS login
          </Typography>
          <Card>
            <CardContent>{error}</CardContent>
            <CardActions>
              <Button
                fullWidth
                color="secondary"
                component={WLink}
                disabled={error === null}
                to="/sign-in/"
                variant="contained"
              >
                Back to sign in
              </Button>
            </CardActions>
          </Card>
        </Stack>
      )}
    </Page>
  );
};

export default NHSCallback;

import { useEffect } from "react";

import Loader from "src/components/Onboarding/Loader";
import { useGetCustomTokenForRedirectQuery } from "src/queries";

const SubdomainRedirect = () => {
  const { data: token, isLoading } = useGetCustomTokenForRedirectQuery();
  useEffect(() => {
    if (token) {
      window.location.href = import.meta.env.DEV
        ? `http://localhost:3000/sign-in/?redirect=${token}`
        : `https://app.curaleafclinic.com/sign-in/?redirect=${token}`;
    } else if (token === undefined && isLoading === false) {
      window.location.assign(
        import.meta.env.DEV
          ? `http://localhost:3000/sign-in/`
          : "https://app.curaleafclinic.com/sign-in/",
      );
    }
  }, [token, isLoading]);

  return <Loader />;
};
export default SubdomainRedirect;

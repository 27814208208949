import {
  EmailField,
  SubmitButton,
  ToastContext,
} from "@curaleaf-international/components";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link as WLink } from "wouter";
import * as yup from "yup";

import ProcessSidebar from "src/components/Onboarding/ProcessSidebar";
import SplitPage from "src/components/Onboarding/SplitPage";
import { useForgottenPasswordMutation } from "src/queries";

interface IForm {
  email: string;
}

const validationSchema = yup.object({
  email: yup.string().email().required(),
});

const ForgottenPassword = () => {
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: sendForgottenPasswordEmail } =
    useForgottenPasswordMutation();
  const methods = useForm<IForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data: IForm) => {
    try {
      await sendForgottenPasswordEmail(data.email);
      addToast(
        "If we have an account matching your email address, you will receive an email with a link to reset your password.",
        "success",
      );
    } catch {
      addToast("Something went wrong. Please try again.", "error");
    }
  };

  return (
    <SplitPage
      invert={true}
      left={<ProcessSidebar />}
      right={
        <Stack spacing={3}>
          <Typography variant="h2">Forgotten password?</Typography>
          <Card>
            <CardContent>
              <Typography sx={{ marginBottom: 2 }}>
                Enter your email below and we will send you a password reset
                link if you have an account registered with us.
              </Typography>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Stack spacing={2}>
                    <EmailField fullWidth label="Email" name="email" required />
                    <SubmitButton fullWidth label="Send password reset link" />
                    <Button
                      component={WLink}
                      to="/sign-in/"
                      color="secondary"
                      variant="outlined"
                    >
                      Sign in instead
                    </Button>
                    <Button
                      color="primary"
                      component={WLink}
                      fullWidth
                      to="/sign-up/"
                      variant="outlined"
                    >
                      Don't have an account? Sign Up
                    </Button>
                  </Stack>
                </form>
              </FormProvider>
            </CardContent>
          </Card>
        </Stack>
      }
      title="Sign In"
    />
  );
};

export default ForgottenPassword;

import LoadingButton from "@mui/lab/LoadingButton";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";

import GoogleLogo from "src/components/Onboarding/GoogleLogo";
import { auth } from "src/firebase";

const GoogleSignInButton = () => {
  const onClick = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithRedirect(auth, provider);
  };

  return (
    <LoadingButton
      fullWidth
      onClick={onClick}
      startIcon={<GoogleLogo ariaHidden={true} />}
      variant="outlined"
      color="secondary"
    >
      Continue with Google
    </LoadingButton>
  );
};

export default GoogleSignInButton;

import {
  EmailField,
  SubmitButton,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { useSendMagicLinkMutation } from "src/queries";

interface IForm {
  email: string;
}

const validationSchema = z.object({
  email: z.string().email("Invalid email"),
});

const EmailPasswordForm = () => {
  const { addToast } = useContext(ToastContext);
  const [sent, setSent] = useState(false);
  const { mutateAsync: sendMagicLink } = useSendMagicLinkMutation();
  const methods = useForm<IForm>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data: IForm) => {
    try {
      await sendMagicLink(data.email);
      setSent(true);
    } catch {
      addToast("Try again", "error");
    }
  };

  if (sent) {
    return (
      <Stack spacing={2}>
        <Typography variant="h5">Sign In Link Sent</Typography>
        <Typography variant="body1">
          If the email you provided is in our system we will send you a link to
          sign in now.
        </Typography>
        <Typography variant="body1">
          If you can't find the email, please check your junk mail.
        </Typography>
      </Stack>
    );
  } else {
    return (
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
          sx={{ width: "100%" }}
        >
          <Stack alignItems="center" spacing={2}>
            <EmailField fullWidth label="Email" name="email" required />
            <SubmitButton fullWidth label="Send Secure Sign In Link" />
          </Stack>
        </Box>
      </FormProvider>
    );
  }
};

export default EmailPasswordForm;

import { fromUnixTime } from "date-fns";
import { z } from "zod";

export const zFirebaseDateSchema = () =>
  z
    .object({ seconds: z.number() })
    .transform((value) => fromUnixTime(value.seconds));

// Some of our data is `""` rather than `null`
export const brokenSchema = <
  Output,
  Def extends z.ZodTypeDef = z.ZodTypeDef,
  Input = Output,
>(
  expected: z.ZodType<Output, Def, Input>,
) => z.union([z.literal("").transform(() => null), expected]);

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Link from "@mui/material/Link";
import { Link as WLink } from "wouter";

import Page from "src/components/Onboarding/Page";

const NotFound = () => (
  <Page title="Not found">
    <Alert severity="error" sx={{ marginTop: 2 }}>
      <AlertTitle>Not Found</AlertTitle>
      This page is not available. Please click{" "}
      <Link component={WLink} to="/sign-in/">
        here
      </Link>{" "}
      to return to the homepage.
    </Alert>
  </Page>
);

export default NotFound;

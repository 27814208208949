import { common } from "@mui/material/colors";
import { alpha, PaletteOptions } from "@mui/material/styles";

import { electricBlue, neutral } from "src/theme/colors";
import { getColors } from "src/theme/utils";

export const createPalette = (): PaletteOptions => {
  return {
    ...getColors(),
    action: {
      active: neutral[500],
      disabled: alpha(neutral[1000], 0.38),
      disabledBackground: alpha(neutral[1000], 0.12),
      focus: alpha(neutral[1000], 0.16),
      hover: alpha(electricBlue.main, 0.08),
      selected: alpha(neutral[1000], 0.12),
    },
    background: {
      default:
        window.location.pathname === "/"
          ? common.white
          : alpha(neutral[20], 0.05),
      paper: common.white,
    },
    divider: alpha(neutral[1000], 0.16),
    mode: "light",
    neutral,
    text: {
      primary: neutral[1000],
      secondary: neutral[500],
      disabled: alpha(neutral[1000], 0.38),
    },
  };
};

import { createTheme, Direction, ThemeOptions } from "@mui/material/styles";

import { createComponents } from "src/theme/base/create-components";
import { createTypography } from "src/theme/base/create-typography";

interface Config {
  baseFont: string;
  direction?: Direction;
  headingFont: string;
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    onboarding: true;
  }
}
declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    landingH1: React.CSSProperties;
    landingH2: React.CSSProperties;
    landingH3: React.CSSProperties;
    landingH4: React.CSSProperties;
    landingH5: React.CSSProperties;
    landingH6: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    landingH1?: React.CSSProperties;
    landingH2?: React.CSSProperties;
    landingH3?: React.CSSProperties;
    landingH4?: React.CSSProperties;
    landingH5?: React.CSSProperties;
    landingH6?: React.CSSProperties;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    landingH1: true;
    landingH2: true;
    landingH3: true;
    landingH4: true;
    landingH5: true;
    landingH6: true;
  }
}
declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    landing: true;
    onboarding: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    accent: true;
  }
}
// Here we do not modify the "palette" and "shadows" because "light" and "dark" mode
// may have different values.

export const createOptions = (config: Config): ThemeOptions => {
  const { direction = "ltr" } = config;
  const baseTheme = createTheme();
  const breakpoints = {
    ...baseTheme.breakpoints,
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  };

  return {
    breakpoints: breakpoints,
    components: createComponents(),
    direction,
    shape: {
      borderRadius: 8,
    },
    typography: createTypography(
      config.baseFont,
      config.headingFont,
      breakpoints,
    ),
  };
};

import { common } from "@mui/material/colors";
import { alpha, PaletteOptions } from "@mui/material/styles";

import { electricBlue, neutral } from "src/theme/colors";
import { getColors } from "src/theme/utils";

export const createPalette = (): PaletteOptions => {
  return {
    ...getColors(),
    action: {
      active: common.white,
      disabled: alpha(neutral[100], 0.38),
      disabledBackground: alpha(neutral[100], 0.12),
      focus: alpha(neutral[100], 0.16),
      hover: alpha(electricBlue.main, 0.08),
      selected: alpha(neutral[100], 0.12),
    },
    background: {
      default: window.location.pathname === "/" ? "#222533" : neutral[1000],
      paper: "#161E2B",
    },
    divider: alpha(common.white, 0.16),
    mode: "dark",
    neutral,
    text: {
      primary: common.white,
      secondary: common.white,
      disabled: "rgba(255, 255, 255, 0.48)",
    },
  };
};

import { getAuth, getIdToken } from "firebase/auth";

export const getFirebasePatientAuthHeaders = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const token = await getIdToken(user!);

  return {
    "firebase-patient-id-token": token,
    "firebase-patient-uid": user!.uid,
  };
};

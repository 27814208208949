import { ScrollToTop } from "@curaleaf-international/components";
import { Route, Switch } from "wouter";

import PrivateRoute from "src/components/Onboarding/PrivateRoute";
import PublicRoute from "src/components/Onboarding/PublicRoute";
import { AuthUser } from "src/models/authUser";
import { Patient } from "src/models/patient";
import AboutGP from "src/pages/AboutGP";
import AboutYou from "src/pages/AboutYou";
import Address from "src/pages/Address";
import Eligibility from "src/pages/Eligibility";
import EmailUnverified from "src/pages/EmailUnverified";
import ForgottenPassword from "src/pages/ForgottenPassword";
import HealthInfo from "src/pages/HealthInfo";
import Homepage from "src/pages/LandingPage";
import MedicalRecords from "src/pages/MedicalRecords";
import MoreInformationRequired from "src/pages/MoreInformationRequired";
import NHSCallback from "src/pages/NHSCallback";
import NotFound from "src/pages/NotFound";
import Onboarding from "src/pages/Onboarding";
import ReviewingRecords from "src/pages/ReviewingRecords";
import SignIn from "src/pages/SignIn";
import SignUp from "src/pages/SignUp";
import SubdomainRedirect from "src/pages/SubdomainRedirect";

const isRejected = (patient: Patient) =>
  patient.decisionToPrescribe === "No" ||
  patient.onboardingStatus === "rejected" ||
  ![
    "Accepted",
    "Pending",
    "Accepted - Treatment on hold",
    "More information required",
    null,
  ].includes(patient.status);

const fixedPages = (user: AuthUser, patient: Patient) => {
  if (!user.emailVerified) {
    return "/email-unverified/";
  } else if (
    ["completed", "documentation"].includes(patient.onboardingStatus) ||
    isRejected(patient)
  ) {
    return "/redirect/";
  } else {
    return null;
  }
};

const onboarding = (user: AuthUser, patient: Patient) => {
  const to = fixedPages(user, patient);
  if (to !== null) {
    return to;
  } else {
    return null;
  }
};

const ThrowError = () => {
  throw new Error("Test Error");
};

const Router = () => (
  <>
    <ScrollToTop />
    <Switch>
      <Route path="/">
        <Homepage />
      </Route>
      <PublicRoute path="/sign-in/">
        <SignIn />
      </PublicRoute>
      <PublicRoute path="/sign-up/">
        <SignUp />
      </PublicRoute>
      <PrivateRoute path="/onboarding/" redirectCheck={onboarding}>
        <Onboarding />
      </PrivateRoute>
      <PrivateRoute path="/onboarding/eligibility/" redirectCheck={onboarding}>
        <Eligibility />
      </PrivateRoute>
      <PrivateRoute path="/onboarding/about-you/" redirectCheck={onboarding}>
        <AboutYou />
      </PrivateRoute>
      <PrivateRoute path="/onboarding/address/" redirectCheck={onboarding}>
        <Address />
      </PrivateRoute>
      <PrivateRoute path="/onboarding/health/" redirectCheck={onboarding}>
        <HealthInfo />
      </PrivateRoute>
      <PrivateRoute path="/onboarding/gp/" redirectCheck={onboarding}>
        <AboutGP />
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/medical-records/"
        redirectCheck={onboarding}
      >
        <MedicalRecords />
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/reviewing/"
        redirectCheck={(user: AuthUser, patient: Patient) =>
          [
            "patientRetrievingScr",
            "reviewingMedicalInfo",
            "sapphireRetrievingScr",
          ].includes(patient.onboardingStatus) && isRejected(patient) === false
            ? null
            : (fixedPages(user, patient) ?? "/sign-in/")
        }
      >
        <ReviewingRecords />
      </PrivateRoute>
      <PrivateRoute
        path="/onboarding/more-information-required/"
        redirectCheck={(user: AuthUser, patient: Patient) =>
          [
            "moreInformationRequiredClinician",
            "moreInformationRequired",
          ].includes(patient.onboardingStatus) && isRejected(patient) === false
            ? null
            : (fixedPages(user, patient) ?? "/sign-in/")
        }
      >
        <MoreInformationRequired />
      </PrivateRoute>
      <PrivateRoute
        path="/redirect/"
        redirectCheck={(user: AuthUser, patient: Patient) =>
          ["completed", "documentation"].includes(patient.onboardingStatus) ||
          isRejected(patient)
            ? null
            : (fixedPages(user, patient) ?? "/sign-in/")
        }
      >
        <SubdomainRedirect />
      </PrivateRoute>
      <Route path="/nhs-callback/">
        <NHSCallback />
      </Route>
      <Route path="/email-unverified/">
        <EmailUnverified />
      </Route>
      <PublicRoute path="/forgotten-password/">
        <ForgottenPassword />
      </PublicRoute>
      <Route path="/control/fe-error/">
        <ThrowError />
      </Route>
      <Route path="/(.*)">
        <NotFound />
      </Route>
    </Switch>
  </>
);

export default Router;

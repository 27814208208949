import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import type { Components } from "@mui/material/styles/components";

export const createComponents = (): Components => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderWidth: "1px",
          borderRadius: "8px",
          padding: "10px 18px",
          gap: "6px",
        },
        startIcon: {
          margin: 0,
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "landing" },
          style: {
            borderRadius: "12px",
          },
        },
        {
          props: { variant: "onboarding" },
          style: {
            borderRadius: "8px",
          },
        },
      ],
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "landingH6",
        },
      },
      styleOverrides: {
        root: {
          padding: "32px 24px 16px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: "1rem",
          lineHeight: 1.4,
          padding: "18px 24px",
          borderRadius: "100px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: "1px",
          borderRadius: "2px",
          fontSize: "14px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: 4,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          [`&.${outlinedInputClasses.focused}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderWidth: "1px",
            },
          },
        },
        input: {
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: "20px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
          boxShadow: "none",
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 16,
      },
    },
  };
};

import { ToastContext } from "@curaleaf-international/components";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import {
  ChangeEvent,
  DragEvent,
  useContext,
  useId,
  useRef,
  useState,
} from "react";

const ALLOWED_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
  "image/tiff",
  "image/tif",
  "image/ief",
];

interface IProps {
  onDrop: (files: File[]) => Promise<void>;
  multiple?: boolean;
}

const Dropzone = ({ onDrop, multiple = true }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const uid = useId();
  const [over, setOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);

  const filterFiles = (files: FileList) => {
    const valid = [];
    const invalid = [];
    for (const file of files) {
      if (ALLOWED_FILE_TYPES.includes(file.type)) {
        valid.push(file);
      } else {
        invalid.push(file.name);
      }
    }
    if (invalid.length > 0) {
      addToast(
        `The following files are not supported ${invalid.join(", ")}`,
        "error",
      );
    }
    return valid;
  };

  const doDrop = async (filelist: FileList) => {
    setLoading(true);
    try {
      await onDrop(filterFiles(filelist));
    } finally {
      setLoading(false);
    }
  };

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.files && event.target.files.length > 0) {
      await doDrop(event.target.files);
    }
  };

  const onDrag = (value: boolean) => (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setOver(value);
  };

  const onLocalDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setOver(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      await doDrop(event.dataTransfer.files);
    }
  };

  return (
    <Box
      onDragLeave={onDrag(false)}
      onDragOver={onDrag(true)}
      onDrop={onLocalDrop}
      sx={{ marginBottom: 3 }}
    >
      <Box
        ref={ref}
        component="input"
        disabled={loading}
        id={uid}
        multiple={multiple}
        onChange={onChange}
        sx={{ display: "none" }}
        type="file"
      />
      <Box
        component="label"
        htmlFor={uid}
        sx={{
          backgroundColor: "other.lightGrey",
          border: "2px dashed",
          borderColor: over ? "info.main" : "text.secondary",
          color: "text.main",
          display: "block",
          padding: 4,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Grid alignItems="center" container direction="column" spacing={1}>
          <Grid size={12}>
            {loading ? <CircularProgress size={24} /> : <CloudUploadIcon />}
          </Grid>
          <Grid size={12} sx={{ display: { xs: "none", md: "block" } }}>
            <Typography variant="body1">Drop files here</Typography>
            <Typography variant="body1">or</Typography>
          </Grid>
          <Grid size={12}>
            <Button
              aria-haspopup="true"
              color="primary"
              onClick={() => {
                if (ref.current) ref.current.click();
              }}
              variant="outlined"
            >
              Browse files
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dropzone;

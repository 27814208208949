import { useQueryClient } from "@tanstack/react-query";
import { formatISO } from "date-fns";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { db } from "src/firebase";
import {
  PendingAssistedReferral,
  newPendingAssistedReferral,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export const usePendingAssistedReferralQuery = () => {
  const { user } = useContext(AuthContext);

  return useQuery<PendingAssistedReferral>({
    queryKey: ["pendingAssistedReferral"],
    queryFn: async () => {
      const snapshot = await getDoc(doc(db, "PendingAR", user!.id));
      return newPendingAssistedReferral(snapshot.data());
    },
    enabled: user !== undefined && user !== null,
  });
};

interface IEligibilityData {
  communicationPreferences: ("email" | "text" | "phone")[];
  marketingConsent: boolean;
  referrer: string;
}

export const useDeclareEligibleMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async (data: IEligibilityData) => {
      await updateDoc(doc(db, `PendingAR/${user!.id}/`), {
        AppointmentCommunicationPreferences: data.communicationPreferences,
        MarketingConsent: data.marketingConsent,
        RefFrom: data.referrer,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["pendingAssistedReferral"],
      });
    },
  });
};

interface IAboutYouData {
  attribution: string;
  carer: string;
  carerEmail?: string;
  carerPhonenumber?: string;
  carerFirstName?: string;
  carerLastName?: string;
  carerTitle?: string;
  dateOfBirth: Date;
  firstName: string;
  lastName: string;
  veteran: boolean;
  occupation: string;
  other?: string;
  pharmacy?: string;
  phonenumber: string;
  sex: string;
  title: string;
  ukHealthSystemNumber: string;
}

export const useUpdateAboutYouMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async (data: IAboutYouData) => {
      let porg = "No";
      if (data.carer === "Carer") {
        porg = "Yes - Carer";
      } else if (data.carer === "Guardian") {
        porg = "Yes - Guardian";
      }
      await updateDoc(doc(db, `PendingAR/${user!.id}/`), {
        CgEmail: data.carerEmail,
        CgFirstName: data.carerFirstName,
        CgLastName: data.carerLastName,
        CgPhoneNumber: data.carerPhonenumber,
        CgTitle: data.carerTitle,
        DoB: formatISO(data.dateOfBirth, { representation: "date" }),
        Email: user!.email,
        FirstName: data.firstName,
        Gender: data.sex,
        How_hear_about_us:
          data.attribution === "Other" ? data.other : data.attribution,
        LastName: data.lastName,
        NNumber: data.ukHealthSystemNumber,
        Veteran: data.veteran,
        Occupation: data.occupation,
        PartnerCode: data.pharmacy,
        Phonenumber: data.phonenumber,
        PorG: porg,
        Title: data.title,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["pendingAssistedReferral"],
      });
    },
  });
};

interface IAddressData {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  county: string;
  country: string;
  postcode: string;
}

export const useUpdatePendingARAddressMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async (data: IAddressData) => {
      await updateDoc(doc(db, `PendingAR/${user!.id}/`), {
        AddressLine1: data.addressLine1,
        AddressLine2: data.addressLine2,
        City: data.city,
        County: data.county,
        Country: data.country,
        Postcode: data.postcode,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["pendingAssistedReferral"],
      });
    },
  });
};

interface IHealthInfoData {
  allergies: string;
  clinicName: string;
  condition: string;
  isTransfer: boolean;
  medicineHistory: string;
  painHistory: string;
  psychiatricHistory: string;
}

export const useUpdateHealthInfoMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async (data: IHealthInfoData) => {
      await updateDoc(doc(db, `PendingAR/${user!.id}/`), {
        ClinicName: data.clinicName,
        IsTransfer: data.isTransfer,
        KnownAllergies: data.allergies,
        MedHist: data.medicineHistory,
        PainHist: data.painHistory,
        PsychHist: data.psychiatricHistory,
        Reason: data.condition,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["pendingAssistedReferral"],
      });
    },
  });
};

interface IGPInfoData {
  addressLine1: string;
  addressLine2: string;
  city: string;
  email: string;
  name: string;
  phoneNumber: string;
  postcode: string;
}

export const useUpdateGPInfoMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async (data: IGPInfoData) => {
      await updateDoc(doc(db, `PendingAR/${user!.id}/`), {
        GpAddressLine1: data.addressLine1,
        GpAddressLine2: data.addressLine2,
        GpCity: data.city,
        GpEmail: data.email,
        GpName: data.name,
        GpPhonenumber: data.phoneNumber,
        GpPostcode: data.postcode,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["pendingAssistedReferral"],
      });
    },
  });
};

export const useSetPendingARCompleteMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async () => {
      await updateDoc(doc(db, `PendingAR/${user!.id}/`), {
        pendingARComplete: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["pendingAssistedReferral"],
      });
    },
  });
};

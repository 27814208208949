import LogoutIcon from "@mui/icons-material/Logout";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { alpha, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { visuallyHidden } from "@mui/utils";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import Logo from "src/components/Onboarding/Logo";
import { useSignOutMutation } from "src/queries/auth";

const sxToolbar = {
  paddingLeft: "env(safe-area-inset-left)",
  paddingTop: "env(safe-area-inset-top)",
  display: "flex",
  justifyContent: "space-between",
};

const TopBar = () => {
  const { user } = useContext(AuthContext);
  const { mutateAsync: logout } = useSignOutMutation();
  const theme = useTheme();

  return (
    <>
      <AppBar
        enableColorOnDark
        position="fixed"
        sx={{
          backgroundColor: "brand.main",
          color: "brand.contrastColor",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={sxToolbar}>
          <Button color="inherit" href={window.location.origin}>
            <Logo height={40} color={theme.palette.brand.contrastText} />
            <Box sx={visuallyHidden}>Return to homepage</Box>
          </Button>
          {user ? (
            <IconButton
              onClick={async () => {
                await logout();
              }}
            >
              <LogoutIcon
                sx={{
                  color: "brand.contrastText",
                  "&:hover": {
                    color: alpha(theme.palette.brand.contrastText, 0.8),
                  },
                }}
              />
            </IconButton>
          ) : null}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopBar;

import { backdropClasses } from "@mui/material/Backdrop";
import { common } from "@mui/material/colors";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { alpha, PaletteOptions } from "@mui/material/styles";
import type { Components } from "@mui/material/styles/components";

import {
  darkGreyBlue,
  electricBlue,
  fadedRed,
  irishGreen,
  neutral,
  papayaOrange,
} from "src/theme/colors";

interface Config {
  palette: PaletteOptions;
}

export const createComponents = ({ palette }: Config): Components => {
  return {
    MuiAlert: {
      styleOverrides: {
        icon: {
          color:
            window.location.pathname === "/"
              ? `${common.white} !important`
              : "",
        },
        root: {
          color: common.white,
          borderColor: common.white,
          "&.MuiAlert-colorInfo": {
            backgroundColor:
              window.location.pathname === "/"
                ? "transparent"
                : alpha(common.white, 0.06),
          },
          "&.MuiAlert-colorSuccess": {
            color: irishGreen.main,
            backgroundColor: alpha(irishGreen.main, 0.16),
          },
          "&.MuiAlert-colorWarning": {
            color: papayaOrange.main,
            backgroundColor: alpha(papayaOrange.main, 0.16),
          },
          "&.MuiAlert-colorError": {
            color: fadedRed.main,
            backgroundColor: alpha(fadedRed.main, 0.16),
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(common.white, 0.8),
          color: common.black,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          [`&:not(.${backdropClasses.invisible})`]: {
            backgroundColor: neutral[800],
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: "contained" },
              style: {
                backgroundColor: palette.brand?.contrastText,
                color: palette.brand?.main,
                "&:hover": {
                  backgroundColor: alpha(
                    palette.brand?.contrastText ?? common.white,
                    0.8,
                  ),
                },
                "&.MuiButton-loading": {
                  backgroundColor: alpha(common.white, 0.16),
                  color: "transparent",
                },
              },
            },
            {
              props: { variant: "outlined" },
              style: {
                "&:hover": {
                  backgroundColor: alpha(common.white, 0.06),
                },
              },
            },
            {
              props: { variant: "outlined", color: "secondary" },
              style: {
                backgroundColor: "transparent",
                borderColor: alpha(common.white, 0.16),
                "&:hover": {
                  backgroundColor: alpha(common.white, 0.06),
                },
              },
            },
          ],
          borderColor: palette.brand?.contrastText,
          color: palette.brand?.contrastText,
          ["&.Mui-disabled"]: {
            color: alpha(common.white, 0.4),
            backgroundColor: alpha(common.white, 0.16),
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: "onboarding" },
              style: {
                border: "1px solid",
                borderColor: alpha(common.white, 0.16),
              },
            },
          ],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        icon: {
          color: palette.action!.active,
        },
        root: {
          borderColor: palette.neutral![700],
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: alpha(darkGreyBlue.contrastText, 0.16),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: darkGreyBlue.contrastText,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: darkGreyBlue.contrastText,
          textDecorationColor: darkGreyBlue.contrastText,
          "&:hover": {
            color: alpha(darkGreyBlue.contrastText, 0.8),
            textDecorationColor: alpha(darkGreyBlue.contrastText, 0.8),
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "& .MuiMenu-paper": {
            marginTop: "5px",
            border: "1px solid",
            borderColor: electricBlue.main,
            borderRadius: "6px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover": {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: electricBlue.main,
            },
          },
        },
        notchedOutline: {
          borderColor: alpha(common.white, 0.2),
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiDialog-paper": {
            backgroundColor: "#161E2B",
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background?.paper,
          borderRadius: "6px",
        },
      },
    },
  };
};

import {
  CheckboxField,
  DateField,
  EmailField,
  SelectField,
  SubmitButton,
  TextField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { differenceInYears } from "date-fns";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import OccupationSelectField from "src/components/Onboarding/OccupationSelectField";
import OnboardingStepper from "src/components/Onboarding/OnboardingStepper";
import PartnerPharmacySelectField from "src/components/Onboarding/PartnerPharmacySelectField";
import SplitPage from "src/components/Onboarding/SplitPage";
import {
  usePendingAssistedReferralQuery,
  usePatientQuery,
  useUpdateAboutYouMutation,
  useUpdateOnboardingStatusMutation,
} from "src/queries";

const TITLES = [
  { label: "Mrs", value: "Mrs" },
  { label: "Mr", value: "Mr" },
  { label: "Miss", value: "Miss" },
  { label: "Ms", value: "Ms" },
  { label: "Mx", value: "Mx" },
  { label: "Dr", value: "Dr" },
  { label: "Prof", value: "Prof" },
  { label: "Sir", value: "Sir" },
  { label: "Dame", value: "Dame" },
  { label: "Lord", value: "Lord" },
  { label: "Lady", value: "Lady" },
];

const ATTRIBUTIONS = [
  {
    label: "My doctor or healthcare professional",
    value: "My doctor or healthcare professional",
  },
  {
    label: "Recommended by a patient of Curaleaf Clinic",
    value: "Recommended by a patient of Curaleaf Clinic",
  },
  {
    label: "Billboard",
    value: "Billboard",
  },
  {
    label: "Mind, Body & Spirit Festival",
    value: "Mind, Body & Spirit Festival",
  },
  {
    label: "Recommended by a friend",
    value: "Recommended by a friend",
  },
  { label: "Podcast / Radio", value: "Podcast / Radio" },
  { label: "Google Search", value: "Google Search" },
  { label: "Social Media", value: "Social Media" },
  { label: "Press", value: "Press" },
  { label: "TV", value: "TV" },
  { label: "YouTube", value: "YouTube" },
  { label: "Local pharmacy/leaflet", value: "Local pharmacy/leaflet" },
  { label: "Other (please specify below)", value: "Other" },
];

interface IForm {
  attribution: string;
  carer: string;
  carerEmail?: string;
  carerPhonenumber?: string;
  carerFirstName?: string;
  carerLastName?: string;
  carerTitle?: string;
  dateOfBirth: Date;
  firstName: string;
  lastName: string;
  veteran: boolean;
  occupation: string;
  other?: string;
  pharmacy?: string;
  phonenumber: string;
  sex: string;
  title: string;
  ukHealthSystemNumber: string;
}

const FormSchema = z
  .object({
    attribution: z.string(),
    carer: z.string(),
    carerEmail: z.string().optional(),
    carerFirstName: z.string().optional(),
    carerLastName: z.string().optional(),
    carerTitle: z.string().optional(),
    carerPhonenumber: z.string().optional(),
    dateOfBirth: z
      .date()
      .refine((value) => differenceInYears(new Date(), new Date(value)) >= 18, {
        message: "You must be older than 18 to receive treatment",
      }),
    firstName: z.string(),
    lastName: z.string(),
    occupation: z.string(),
    veteran: z.boolean(),
    other: z.string().optional(),
    pharmacy: z.string().optional(),
    phonenumber: z.string(),
    sex: z.string(),
    title: z.string(),
    ukHealthSystemNumber: z
      .string()
      .refine((val) => val === "" || /^[0-9]{10}$/.test(val), {
        message: "Invalid number",
      }),
  })
  .superRefine((data, ctx) => {
    if (
      data.carer !== "No" &&
      !data.carerEmail &&
      !data.carerFirstName &&
      !data.carerLastName &&
      !data.carerPhonenumber &&
      !data.carerTitle
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "All carer/guardian information is required",
        path: ["carer"],
      });
    }
    if (data.attribution === "Local pharmacy/leaflet" && !data.pharmacy) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Pharmacy is required",
        path: ["pharmacy"],
      });
    }
    if (data.attribution === "Other" && !data.other) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please provide where you heard about us",
        path: ["other"],
      });
    }
  });

const AboutYou = () => {
  const [_, setLocation] = useLocation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: patient } = usePatientQuery();
  const { data: assistedReferral } = usePendingAssistedReferralQuery();
  const { mutateAsync: updateOnboardingStatus } =
    useUpdateOnboardingStatusMutation();
  const { mutateAsync: updateAboutYou } = useUpdateAboutYouMutation();

  let defaultCarer = assistedReferral?.carer ?? "";
  if (assistedReferral?.carer === "Yes - Carer") {
    defaultCarer = "Carer";
  } else if (assistedReferral?.carer === "Yes - Guardian") {
    defaultCarer = "Guardian";
  }

  let defaultAttribution = "";
  let defaultOther = "";
  if (assistedReferral?.attribution) {
    if (
      Object.values(ATTRIBUTIONS)
        .map((attribution) => attribution.value)
        .includes(assistedReferral.attribution)
    ) {
      defaultAttribution = assistedReferral.attribution;
    } else {
      defaultAttribution = "Other";
      defaultOther = assistedReferral.attribution;
    }
  }

  const defaultValues = {
    attribution: defaultAttribution,
    carer: defaultCarer,
    carerEmail: assistedReferral?.carerEmail ?? "",
    carerPhonenumber: assistedReferral?.carerPhonenumber ?? "",
    carerFirstName: assistedReferral?.carerFirstName ?? "",
    carerLastName: assistedReferral?.carerLastName ?? "",
    carerTitle: assistedReferral?.carerTitle ?? "",
    dateOfBirth: assistedReferral?.dateOfBirth ?? (null as unknown as Date),
    firstName: assistedReferral?.firstName ?? "",
    lastName: assistedReferral?.lastName ?? "",
    veteran: assistedReferral?.veteran ?? false,
    occupation: assistedReferral?.occupation ?? "",
    other: defaultOther,
    pharmacy:
      assistedReferral?.partnerPharmacy ??
      localStorage.getItem("partnerCode") ??
      "",
    phonenumber: assistedReferral?.phonenumber ?? "",
    sex: assistedReferral?.sex ?? "",
    title: assistedReferral?.title ?? "",
    ukHealthSystemNumber: assistedReferral?.ukHealthSystemNumber ?? "",
  };

  const methods = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  useEffect(
    () => methods.reset(defaultValues),
    [assistedReferral, methods.reset],
  );

  const values = methods.watch();

  useEffect(() => {
    if (values.occupation === "Heavy goods vehicle (HGV) driver") {
      setDialogOpen(true);
    }
  }, [values.occupation]);

  const onSubmit = async (data: IForm) => {
    await updateAboutYou(data);

    if (patient!.onboardingStatus === "genInfo") {
      await updateOnboardingStatus("userAddress");
    }
    setLocation("/onboarding/address/");
  };

  return (
    <>
      <Dialog open={dialogOpen} scroll="body">
        <DialogTitle variant="h4">HGV Drivers</DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            Please note, we may not be able to prescribe treatment for HGV
            drivers. Contact the patient support team for more information.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} variant="contained">
            Acknowledge & Continue
          </Button>
        </DialogActions>
      </Dialog>
      <SplitPage
        left={<OnboardingStepper />}
        right={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <Typography variant="h2">About You</Typography>
                <Card>
                  <CardContent>
                    <Stack spacing={2}>
                      <SelectField
                        fullWidth
                        label="Are you a carer or guardian of the patient?"
                        name="carer"
                        options={[
                          { value: "No" },
                          { label: "Yes - Carer", value: "Carer" },
                          { label: "Yes - Guardian", value: "Guardian" },
                        ]}
                        required
                      />
                      {values.carer !== "No" && values.carer !== "" ? (
                        <>
                          <SelectField
                            fullWidth
                            label={`Title of ${values.carer}`}
                            name="carerTitle"
                            options={TITLES}
                            required
                          />
                          <TextField
                            fullWidth
                            label={`First Name of ${values.carer}`}
                            name="carerFirstName"
                            required
                          />
                          <TextField
                            fullWidth
                            label={`Last Name of ${values.carer}`}
                            name="carerLastName"
                            required
                          />
                          <EmailField
                            fullWidth
                            label={`Email of ${values.carer}`}
                            name="carerEmail"
                            required
                          />
                          <TextField
                            fullWidth
                            label={`Phone Number of ${values.carer}`}
                            name="carerPhonenumber"
                            required
                          />
                        </>
                      ) : null}
                      <SelectField
                        fullWidth
                        label="Title"
                        name="title"
                        options={TITLES}
                        required
                      />
                      <TextField
                        fullWidth
                        label="First Name"
                        name="firstName"
                        required
                      />
                      <TextField
                        fullWidth
                        label="Last Name"
                        name="lastName"
                        required
                      />
                      <DateField
                        fullWidth
                        label="Date of Birth"
                        name="dateOfBirth"
                        required
                      />
                      <SelectField
                        fullWidth
                        label="Sex"
                        name="sex"
                        options={[{ value: "Female" }, { value: "Male" }]}
                        required
                      />
                      <TextField
                        fullWidth
                        label="Phone Number"
                        name="phonenumber"
                        required
                      />
                      <TextField
                        fullWidth
                        label="UK Health System Number e.g. NHS/CHI/HCN"
                        name="ukHealthSystemNumber"
                      />
                      <CheckboxField
                        fullWidth
                        label="Are you a UK HM Veteran?"
                        helperText="(served for at least one day in His Majesty's Armed Forces), and able to prove?"
                        name="veteran"
                        required
                      />
                      <OccupationSelectField
                        fullWidth
                        label="Occupation"
                        name="occupation"
                        required
                      />
                      <SelectField
                        fullWidth
                        label="Where did you hear about us?"
                        name="attribution"
                        options={ATTRIBUTIONS}
                        required
                      />
                      {values.attribution === "Other" ? (
                        <TextField
                          fullWidth
                          label="Please specify where you heard about us"
                          name="other"
                          required
                        />
                      ) : null}
                      {values.attribution === "Local pharmacy/leaflet" ? (
                        <PartnerPharmacySelectField
                          fullWidth
                          label="Pharmacy branch"
                          name="pharmacy"
                          required
                        />
                      ) : null}
                    </Stack>
                  </CardContent>
                  <CardActions>
                    <SubmitButton
                      disabled={patient === undefined}
                      fullWidth
                      label="Save & Continue"
                    />
                  </CardActions>
                </Card>
              </Stack>
            </form>
          </FormProvider>
        }
        title="About You"
      />
    </>
  );
};

export default AboutYou;

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Toolbar from "@mui/material/Toolbar";
import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";

import TopBar from "src/components/Onboarding/TopBar";

interface IProps {
  left: ReactNode;
  right: ReactNode;
  title: string;
  invert?: boolean;
}

const SplitPage = ({ left, right, title, invert }: IProps) => {
  return (
    <>
      <Helmet>
        <title>{title} - Curaleaf Clinic</title>
      </Helmet>
      <TopBar />
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          size={{ xs: 12, md: 4 }}
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            height: { xs: "unset", md: "100%" },
            order: { xs: invert ? 1 : 0, md: 0 },
          }}
        >
          <Toolbar
            sx={{
              display: { xs: invert ? "none" : "block", md: "block" },
            }}
          />
          <Container
            component="main"
            maxWidth="md"
            sx={{
              padding: 2,
              paddingTop: "32px !important",
              overflowY: "auto",
            }}
          >
            {left}
          </Container>
        </Grid>
        <Grid
          size={{ xs: 12, md: 8 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: { xs: "unset", md: "100%" },
            order: { xs: invert ? 0 : 1, md: 1 },
          }}
        >
          <Toolbar
            sx={{
              display: { xs: invert ? "block" : "none", md: "block" },
            }}
          />
          <Container
            component="main"
            maxWidth="md"
            sx={{
              paddingBottom: { xs: invert ? 2 : 19, lg: 2 },
              padding: 2,
              overflowY: "auto",
              paddingTop: "32px !important",
            }}
          >
            {right}
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default SplitPage;
